<script setup lang="ts">
import { LayoutBox } from "@/components/layout";
</script>
<template>
  <LayoutBox>
    <div class="flex h-screen grow flex-col items-center justify-center px-12 py-24">
      <div class="flex flex-col items-center justify-center text-center">
        <h1 class="text-2xl font-semibold">
          <slot name="title"></slot>
        </h1>
        <h2 class="mt-2 text-xl font-medium">
          <slot name="message"></slot>
        </h2>
        <slot name="controls"></slot>
      </div>
    </div>
  </LayoutBox>
</template>
