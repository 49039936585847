<script setup lang="ts">
import ErrorPage from "./ErrorPage.vue";
</script>
<template>
  <ErrorPage>
    <template #title>Destination not found</template>
    <template #message>
      The destination you are looking for does not exist. If you think this is an error, please let us know!
    </template>
  </ErrorPage>
</template>
